<template>
  <div class="d-flex align-items-center flex-nowrap">
    <div class="cursor-pointer" @click="$emit('input', 1)">
      <BaseIcon
        name="star"
        width="24"
        height="24"
        fill="currentColor"
        :class="color1"
      ></BaseIcon>
    </div>
    <div class="cursor-pointer" @click="$emit('input', 2)">
      <BaseIcon
        name="star"
        width="24"
        height="24"
        fill="currentColor"
        :class="color2"
      ></BaseIcon>
    </div>
    <div class="cursor-pointer" @click="$emit('input', 3)">
      <BaseIcon
        name="star"
        width="24"
        height="24"
        fill="currentColor"
        :class="color3"
      ></BaseIcon>
    </div>
    <div class="cursor-pointer" @click="$emit('input', 4)">
      <BaseIcon
        name="star"
        width="24"
        height="24"
        fill="currentColor"
        :class="color4"
      ></BaseIcon>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: {
    BaseIcon
  },
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    niveauId: function() {
      return this.value;
    },
    color1() {
      if (this.value == 0) return "text-muted";
      if (this.value == 1) return "text-warning";
      if (this.value == 2) return "text-warning";
      if (this.value == 3) return "text-warning";
      if (this.value == 4) return "text-warning";
      return "text-warning";
    },

    color2() {
      if (this.value == 0) return "text-muted";
      if (this.value == 1) return "text-muted";
      if (this.value == 2) return "text-warning";
      if (this.value == 3) return "text-warning";
      if (this.value == 4) return "text-warning";
      return "text-warning";
    },

    color3() {
      if (this.value == 0) return "text-muted";
      if (this.value == 1) return "text-muted";
      if (this.value == 2) return "text-muted";
      if (this.value == 3) return "text-warning";
      if (this.value == 4) return "text-warning";
      return "text-warning";
    },

    color4() {
      if (this.value == 0) return "text-muted";
      if (this.value == 1) return "text-muted";
      if (this.value == 2) return "text-muted";
      if (this.value == 3) return "text-muted";
      if (this.value == 4) return "text-warning";
      return "text-warning";
    }
  }
};
</script>
