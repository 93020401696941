<template>
  <div class="w-100">
    <modal :id="'modal_' + evaluation.id" :title="'évaluation n°' + evaluation.id" btn="Ouvrir">
      <template v-slot:title>
        <div class="mt-1 mb-1 p-2 rounded d-flex flex-row align-items-center flex-grow-1" v-background-1>
          <span v-if="evaluation.eval_date"
            >Evaluation du {{ $moment(evaluation.eval_date).format("DD/MM/YYYY") }}</span
          >
          <span v-if="!evaluation.eval_date">Date inconnue</span>
        </div>
      </template>
      <template v-slot:form>
        <base-form
          deleteBtn
          @click="updateFournisseurEval(evaluation)"
          @delete="deleteFournisseurEval(evaluation)"
          :loading="loading"
        >
          <base-form-row row>
            <base-input-date-picker
              v-model="evaluation.eval_date"
              inputText="Date d'évaluation"
              :errors="feedback.eval_date"
            ></base-input-date-picker>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="evaluation.commentaire"
              text="Commentaire"
              :errors="feedback.commentaire"
            ></base-input-area>
          </base-form-row>
          <base-form-row row>
            <select-collaborateur
              class="mb-5"
              v-model.number="evaluation.evaluateur_id"
              :text="'Affecter à un collaborateur'"
            ></select-collaborateur>
          </base-form-row>

          <div class="mt-4">
            <small>
              <table class="table table-responsive-lg rounded border rounded">
                <thead class="rounded">
                  <tr>
                    <th scope="col">Critère</th>
                    <th class="align-middle" scope="col">Qualité</th>
                    <th class="align-middle" scope="col">Coût</th>
                    <th class="align-middle" scope="col">Délais</th>
                    <th class="align-middle" scope="col">SSER</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr>
                    <th class="align-middle" scope="row">1 étoile</th>
                    <td class="align-middle">
                      Non conformités majeures identifiées dans le(s) Produit(s) ou service(s)
                    </td>
                    <td class="align-middle">Supérieur (>10%) au prix moyen du marché</td>
                    <td class="align-middle">Retard supérieur à 5 jours ouvrés</td>
                    <td class="align-middle">Non conformités majeure(s) identifiée(s)</td>
                  </tr>
                  <tr>
                    <th class="align-middle" scope="row">2 étoiles</th>
                    <td class="align-middle">
                      Non conformités mineures identifiées dans le(s) Produit(s) ou service(s)
                    </td>
                    <td class="align-middle">Entre 0 et 10% supérieur au prix moyen du marché</td>
                    <td class="align-middle">Retard inférieur ou égal à 5 jours ouvrés</td>
                    <td class="align-middle">Non-conformité(s) mineure(s) identifiée(s)</td>
                  </tr>
                  <tr>
                    <th class="align-middle" scope="row">3 étoiles</th>
                    <td class="align-middle">Produit(s) ou service(s) conforment aux exigences</td>
                    <td class="align-middle">Conforme au prix du marché</td>
                    <td class="align-middle">Conforme à la commande</td>
                    <td class="align-middle">Conformes aux exigences Arkadia</td>
                  </tr>
                  <tr>
                    <th class="align-middle" scope="row">4 étoiles</th>
                    <td class="align-middle">Produit(s) ou service(s) supérieurs aux exigences</td>
                    <td class="align-middle">Prix inférieur au prix du marché</td>
                    <td class="align-middle">Antérieur à la date indiquée dans la commande</td>
                    <td class="align-middle">Niveau de maitrise supérieur aux exigences Arkadia</td>
                  </tr>
                </tbody>
              </table>
            </small>
          </div>

          <base-form-row row class="align-items-center">
            <div class="d-flex">
              <div style="min-width: 5rem">Qualité</div>
              <base-input-stars
                v-model="evaluation.eval_1"
                inputText="Eval1"
                :errors="feedback.eval_1"
              ></base-input-stars>
            </div>
          </base-form-row>
          <base-form-row row class="align-items-center">
            <div class="d-flex">
              <div style="min-width: 5rem">Coût</div>
              <base-input-stars
                v-model="evaluation.eval_2"
                inputText="Eval2"
                :errors="feedback.eval_2"
              ></base-input-stars>
            </div>
          </base-form-row>
          <base-form-row row class="align-items-center">
            <div class="d-flex">
              <div style="min-width: 5rem">Délais</div>
              <base-input-stars
                v-model="evaluation.eval_3"
                inputText="Eval3"
                :errors="feedback.eval_3"
              ></base-input-stars>
            </div>
          </base-form-row>
          <base-form-row row class="align-items-center mb-3">
            <div class="d-flex">
              <div style="min-width: 5rem">SSER</div>
              <base-input-stars
                v-model="evaluation.eval_4"
                inputText="Eval4"
                :errors="feedback.eval_4"
              ></base-input-stars>
            </div>
          </base-form-row>
        </base-form>
      </template>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import Modal from "@/components/bases/Modal2.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInputStars from "@/components/bases/InputStars.vue";

import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";


export default {
  name: "FournisseursItemEval",
  components: {
    Modal,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInputStars,
    SelectCollaborateur,
  },
  data() {
    return {
      feedback: {},
      loading: false,
    };
  },
  props: ["evaluation"],
  computed: {
    ...mapGetters({ data: "materiel/data" }),
    //...mapMultiRowFields("materiel", ["materielsVerifs"]),
  },
  methods: {
    ...mapActions({
      _updateFournisseurEval: "materiel/updateFournisseurEval",
      _deleteFournisseurEval: "materiel/deleteFournisseurEval",
    }),
    updateFournisseurEval: function (evaluation) {
      this.feedback = {};
      this.loading = true;
      this._updateFournisseurEval(evaluation)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteFournisseurEval: function (evaluation) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteFournisseurEval(evaluation)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
