<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <div>
      <table class="table table-responsive-lg rounded shadow-sm" v-background-3 v-color-1>
        <thead class="text-center">
          <tr>
            <th scope="col" class="text-left">Fournisseurs</th>
            <th scope="col">{{ $moment().year() - 3 }}</th>
            <th scope="col">{{ $moment().year() - 2 }}</th>
            <th scope="col">{{ $moment().year() - 1 }}</th>
            <th scope="col">{{ $moment().year() }}</th>
          </tr>
        </thead>
        <tbody class="">
          <tr class="text-center">
            <th class="text-left" scope="row">Nombre de fournisseurs actifs</th>
            <td>{{ statEvalFournisseur(3).nbrFournisseurs }}</td>
            <td>{{ statEvalFournisseur(2).nbrFournisseurs }}</td>
            <td>{{ statEvalFournisseur(1).nbrFournisseurs }}</td>
            <td>{{ statEvalFournisseur(0).nbrFournisseurs }}</td>
          </tr>
          <tr class="text-center">
            <th class="text-left" scope="row">Nombre de fournisseurs évalués</th>
            <td>{{ statEvalFournisseur(3).nbrFournisseursEvalues }}</td>
            <td>{{ statEvalFournisseur(2).nbrFournisseursEvalues }}</td>
            <td>{{ statEvalFournisseur(1).nbrFournisseursEvalues }}</td>
            <td>{{ statEvalFournisseur(0).nbrFournisseursEvalues }}</td>
          </tr>
          <tr class="text-center">
            <th class="text-left" scope="row">Note moyenne qualité (/4)</th>
            <td>{{ statEvalFournisseur(3).noteMoyenneQualite }}</td>
            <td>{{ statEvalFournisseur(2).noteMoyenneQualite }}</td>
            <td>{{ statEvalFournisseur(1).noteMoyenneQualite }}</td>
            <td>{{ statEvalFournisseur(0).noteMoyenneQualite }}</td>
          </tr>
          <tr class="text-center">
            <th class="text-left" scope="row">Note moyenne coût (/4)</th>
            <td>{{ statEvalFournisseur(3).noteMoyenneCout }}</td>
            <td>{{ statEvalFournisseur(2).noteMoyenneCout }}</td>
            <td>{{ statEvalFournisseur(1).noteMoyenneCout }}</td>
            <td>{{ statEvalFournisseur(0).noteMoyenneCout }}</td>
          </tr>
          <tr class="text-center">
            <th class="text-left" scope="row">Note moyenne délais (/4)</th>
            <td>{{ statEvalFournisseur(3).noteMoyenneDelais }}</td>
            <td>{{ statEvalFournisseur(2).noteMoyenneDelais }}</td>
            <td>{{ statEvalFournisseur(1).noteMoyenneDelais }}</td>
            <td>{{ statEvalFournisseur(0).noteMoyenneDelais }}</td>
          </tr>
          <tr class="text-center">
            <th class="text-left" scope="row">Note moyenne sser (/4)</th>
            <td>{{ statEvalFournisseur(3).noteMoyenneSSER }}</td>
            <td>{{ statEvalFournisseur(2).noteMoyenneSSER }}</td>
            <td>{{ statEvalFournisseur(1).noteMoyenneSSER }}</td>
            <td>{{ statEvalFournisseur(0).noteMoyenneSSER }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <BaseToolBar title="Fournisseur" subtitle="Liste des fournisseurs disponibles">

      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Exporter'"
        @click="exportFournisseurs"
        :loading="loading"
        text="Exporter(.xls)"
      ></BaseButton>

      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Exporter les evals'"
        @click="exportEvalFournisseurs"
        :loading="loading"
        text="Exporter eval(.xls)"
      ></BaseButton>

      <BaseButton
        v-if="havePermission('add_materiels_fournisseurs')"
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter un fournisseur'"
        @click="storeFournisseur"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex mb-3">
      <div class="cursor-pointer mr-3" @click="tab = 1" v-color-1="tab == 1">Actifs</div>
      <div class="cursor-pointer mr-3" @click="tab = 0" v-color-1="tab == 0">Inactifs</div>

      <div class="cursor-pointer mr-3" @click="tab = 2" v-color-1="tab == 2">Agréés</div>
      <div class="cursor-pointer" @click="tab = 3" v-color-1="tab == 3">Non agréés</div>

    </div>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="fournisseur in fournisseursNew" :key="'news_' + fournisseur.id">
          <fournisseurs-item :fournisseur="fournisseur" :canDelete=canDelete :canUpdate=canUpdate></fournisseurs-item>
        </div>
        <div v-if="tab == 1">
          <div v-for="fournisseur in fournisseursActif" :key="'actives_' + fournisseur.id">
            <fournisseurs-item :fournisseur="fournisseur" :canDelete=canDelete :canUpdate=canUpdate></fournisseurs-item>
          </div>
        </div>
        <div v-if="tab == 0">
          <div v-for="fournisseur in fournisseursInactif" :key="'inactives_' + fournisseur.id">
            <fournisseurs-item :fournisseur="fournisseur"></fournisseurs-item>
          </div>
        </div>

        <div v-if="tab == 2">
          <div v-for="fournisseur in fournisseursAgree" :key="'agree_' + fournisseur.id">
            <fournisseurs-item :fournisseur="fournisseur"></fournisseurs-item>
          </div>
        </div>

        <div v-if="tab == 3">
          <div v-for="fournisseur in fournisseursNonAgree" :key="'nonagree_' + fournisseur.id">
            <fournisseurs-item :fournisseur="fournisseur"></fournisseurs-item>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import FournisseursItem from "@/components/materiels/FournisseursItem.vue";
import BaseButton from "@/components/bases/Button.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    BaseToolBar,
    BaseButton,
    FournisseursItem,
  },
  data() {
    return {
      loading: false,
      feedback: {},
      tab: 1,
    };
  },
  methods: {
    ...mapActions({
      _storeFournisseur: "materiel/createFournisseur",
    }),
    storeFournisseur: function () {
      this.feedback = {};
      this.loading = true;
      this._storeFournisseur().finally(() => (this.loading = false));
    },
    exportFournisseurs(){
      this.loading = true;
      this.$http
        .get("/materiels/fournisseur-export")
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
          anchor.target = "_blank";
          anchor.download = "export-fournisseurs.csv";
          anchor.click();

        })
        .finally(() => (this.loading = false));
    },
    exportEvalFournisseurs(){
      this.loading = true;
      this.$http
        .get("/materiels/fournisseur-eval-export")
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
          anchor.target = "_blank";
          anchor.download = "export-fournisseurs-eval.csv";
          anchor.click();

        })
        .finally(() => (this.loading = false));
    },
    statEvalFournisseur(dt) {

      const annee = this.$moment().year() - dt;

      let nbrFournisseurs = this.fournisseurs.filter(
        (d) => 
        (d.etat == 1 && 
          (
            (d.date_fin == null && d.date_debut == null) ||
            (this.$moment(d.date_debut).year() <= annee &&
              (d.date_fin == null ||
                this.$moment(d.date_fin).year() >= annee)
            )
          )
        )
      ).length;


      let noteMoyenneQualite = 0;
      let noteMoyenneCout = 0;
      let noteMoyenneDelais = 0;
      let noteMoyenneSSER = 0;
      let counter = 0;
      let counter2 = 0;
      let counter3 = 0;
      let counter4 = 0;
      let counter5 = 0;

      this.evaluations.forEach((d) => {
        if (this.$moment(d.eval_date).year() == this.$moment().year() - dt) {
          noteMoyenneQualite = noteMoyenneQualite + d.eval_1;
          noteMoyenneCout = noteMoyenneCout + d.eval_2;
          noteMoyenneDelais = noteMoyenneDelais + d.eval_3;
          noteMoyenneSSER = noteMoyenneSSER + d.eval_4;
          counter++;

          if (d.eval_1 > 0) counter2++;
          if (d.eval_2 > 0) counter3++;
          if (d.eval_3 > 0) counter4++;
          if (d.eval_4 > 0) counter5++;
        }
      });

      return {
        nbrFournisseurs: nbrFournisseurs,
        nbrFournisseursEvalues: counter,
        noteMoyenneQualite: (noteMoyenneQualite / counter2).toFixed(1),
        noteMoyenneCout: (noteMoyenneCout / counter3).toFixed(1),
        noteMoyenneDelais: (noteMoyenneDelais / counter4).toFixed(1),
        noteMoyenneSSER: (noteMoyenneSSER / counter5).toFixed(1),
      };
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
    ...mapGetters({ data: "materiel/data" }),
    ...mapMultiRowFields("materiel", ["fournisseurs"]),
    ...mapMultiRowFields("materiel", ["evaluations"]),

    fournisseursNew: function () {
      return this.fournisseurs.filter((fournisseur) => fournisseur.new == true);
    },
    fournisseursActif: function () {
      return this.fournisseurs.filter((fournisseur) => fournisseur.etat == 1);
    },
    fournisseursInactif: function () {
      return this.fournisseurs.filter((fournisseur) => fournisseur.etat == 0);
    },
    fournisseursAgree: function () {
      return this.fournisseurs.filter((fournisseur) => fournisseur.agree == 1);
    },
    fournisseursNonAgree: function () {
      return this.fournisseurs.filter((fournisseur) => fournisseur.agree == 0);
    },
    canDelete: function () {
      console.log("del_materiels_fournisseurs", this.havePermission("del_materiels_fournisseurs") ? true : false);
      return this.havePermission("del_materiels_fournisseurs") ? true : false;

      /*console.log(this.havePermission);
      console.log("canDelete : ", this.havePermission("del_materiels_fournisseurs"));
      return this.havePermission("del_materiels_fournisseurs");*/
    },
    canUpdate: function () {
      console.log("upd_materiels_fournisseurs", this.havePermission("upd_materiels_fournisseurs") ? true : false);
      return this.havePermission("upd_materiels_fournisseurs") ? true : false;

      /*console.log("canUpdate : ", this.havePermission("upd_materiels_fournisseurs"));
      return this.havePermission("upd_materiels_fournisseurs");*/
    },

  },
};
</script>
<style lang="css"></style>
